import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Box, Flex } from "@rebass/grid/emotion";
import { Hide } from "@rebass/hide";
import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import Icon from "react-icons-kit";
import { cameraRetro } from "react-icons-kit/fa/cameraRetro";
import { comments } from "react-icons-kit/fa/comments";
import { folderOpen } from "react-icons-kit/fa/folderOpen";
import { gavel } from "react-icons-kit/fa/gavel";
import { heart } from "react-icons-kit/fa/heart";
import { magic } from "react-icons-kit/fa/magic";
import { search } from "react-icons-kit/fa/search";
import { trophy } from "react-icons-kit/fa/trophy";
import { users } from "react-icons-kit/fa/users";
import { LinkButton } from "../../components/Button";
import Card from "../../components/Card";
import GoogleReviews from "../../components/GoogleReviews";
import Hero from "../../components/Hero";
import ImmoweltBadge from "../../components/ImmoweltBadge";
import Page from "../../components/Page";
import PageBlock from "../../components/PageBlock";
import PageTitle from "../../components/PageTitle";
import ProfileImage from "../../components/ProfileImage";
import References from "../../components/References";
import RoundedImage from "../../components/RoundedImage";
import SectionTeam from "../../components/SectionTeam";
import SectionTitle from "../../components/SectionTitle";
import Service from "../../components/Service";
import Testimonials from "../../components/Testimonials";

const ServiceCard = styled(Card)`
  margin-bottom: 32px;
`;

const BigIcon = styled(Box)`
  text-align: center;
  color: #961452;
  margin-bottom: 32px;
`;

const ImmobilienmaklerGotha = ({ data }) => (
  <Page
    header={
      <Hero fluid={data.homeHeroImage.fluid}>
        <Flex
          alignItems="center"
          flexWrap="wrap"
          css={css`
            max-width: 50em;
          `}
        >
          <Box
            width={[1, null, 2 / 3]}
            css={css`
              text-align: left;
            `}
          >
            <PageTitle>Immobilien in Rudolstadt verkaufen</PageTitle>
            <p>
              Linda Bäumer und ihr Team sind die Experten für den Immobilienverkauf in Rudolstadt. 
              Mit ihrer umfassenden Kenntnis des Rudolstadter Immobilienmarktes stehen sie Ihnen zur Seite. 
              Profitieren Sie von Lindas langjähriger Erfahrung und Fachkompetenz und verkaufen Sie Ihre 
              Immobilie in Thüringen schnell und professionell. Vertrauen Sie auf das Know-how von Linda 
              Bäumer und ihrem Team für einen erfolgreichen Verkaufsprozess.
            </p>
            <p>
              <strong>
                Kostenlos beraten lassen:{" "}
                <a
                  href="tel:036450449933"
                  css={css`
                    color: inherit;
                    font-weight: inherit;
                  `}
                >
                  036450 / 44 99 33
                </a>
              </strong>
            </p>
            <Flex alignItems="stretch" flexWrap="wrap">
              <Hide xsmall>
                <ImmoweltBadge />
              </Hide>
              <GoogleReviews />
            </Flex>
          </Box>
          <Hide width={[1, null, 1 / 3]} paddingLeft={5} xsmall small>
            <ProfileImage version={2} />
            <p>
              <strong>Linda Bäumer</strong>
              <br />
              Immobilienmakler Rudolstadt
            </p>
          </Hide>
        </Flex>
      </Hero>
    }
  >
    <Helmet
      title="Immobilien in Rudolstadt erfolgreich verkaufen"
      meta={[
        {
          name: "description",
          content:
            "Möchten Sie Ihre Immobilie in Rudolstadt verkaufen? Profitieren Sie von unserer Expertise als erfahrene Immobilienmakler und erzielen Sie den besten Preis. ",
        },
      ]}
    />

    {/* Not needed for the moment */}
    {/*

    <PageBlock>
      <Flex justifyContent="center">
        <Box width={[1, null, "50em"]} pt={5}>
          <SectionTitle>
            Mit Kranich Immobilien eine Immobilie zu verkaufen ist kinderleicht
          </SectionTitle>
          <p>
            Linda Bäumer und ihr Team haben{" "}
            <strong>jahrelange Erfahrung beim Verkauf von Immobilien</strong> in
            Thüringen. Kranich Immobilien übernimmt für Sie gerne sämtliche
            Aufgaben des Verkaufprozesses. Von der Suche nach dem perfekten
            Käufer bis zur Erledigung der Bürokratie sorgt Kranich Immobilien
            dafür, dass{" "}
            <strong>der Verkauf so reibungslos und einfach wie möglich</strong>{" "}
            wird.
          </p>
          <SectionTitle>
            Unser Ziel ist 100%ige Kundenzufriedenheit
          </SectionTitle>
          <p>
            Wir verpflichten uns, unseren Kunden jederzeit einen professionellen
            Service zu bieten und gleichzeitig{" "}
            <strong>Ehrlichkeit und Integrität</strong> zu wahren. Unser Ziel
            ist immer eine 100%ige Kundenzufriedenheit! Seit vielen Jahren
            überzeugen wir unsere Partner und Kunden mit Fleiß und
            Vertrauenswürdigkeit.
          </p>
        </Box>
      </Flex>
    </PageBlock>

  */}

    <Flex justifyContent="center">
      <Box width={[1, null, "50em"]} my={4} p={3}>
        <Flex justifyContent="center" flexWrap="wrap" mx={-3}>
          <Box width={[1, null, 1 / 3]} px={3}>
            <BigIcon>
              <Icon size={50} icon={trophy} />
            </BigIcon>
            <h4>Jahrelange Erfahrung</h4>
            <p>
              Kranich Immobilien begleitet seit mehr als 8 Jahren erfolgreich Eigentümer beim Verkauf ihrer Immobilien in Rudolstadt. 
            </p>
          </Box>
          <Box width={[1, null, 1 / 3]} px={3}>
            <BigIcon>
              <Icon size={50} icon={comments} />
            </BigIcon>
            <h4>Transparentes Arbeiten</h4>
            <p>
              Für Linda bildet Ehrlichkeit und Transparenz das Fundament einer erfolgreichen Zusammenarbeit. Sie legt großen Wert darauf, ihren Kunden gegenüber offen und aufrichtig zu sein. 
            </p>
          </Box>
          <Box width={[1, null, 1 / 3]} px={3}>
            <BigIcon>
              <Icon size={50} icon={heart} />
            </BigIcon>
            <h4>Mit Herz und Verstand</h4>
            <p>
              Bei Kranich Immobilien steht der Mensch stets im Mittelpunkt. Wir legen großen Wert auf eine entspannte und freundliche Atmosphäre, in der sich unsere Kunden wohlfühlen können.
            </p>
          </Box>
        </Flex>
      </Box>
    </Flex>

    <PageBlock>
      <Flex justifyContent="center">
        <Box width={[1, null, "50em"]} my={4} p={3}>
          <SectionTitle center>
            Anspruchsvolle, exklusive und individuelle Immobilienvermarktung vom
            Profi
          </SectionTitle>
          <p style={{ textAlign: "center" }}>
            Aus dem Anspruch heraus „alles aus einer Hand – zum Nutzen unserer
            Kunden“ entwickelte das Team um Linda Bäumer Strukturen, das ein
            Rund-Um-Sorglos Verkaufserlebnis ermöglicht.
          </p>
          <Flex flexWrap="wrap">
            <Service
              icon={search}
              title="Immobilienbewertung"
              description="Bei der Immobilienbewertung wird nicht geschätzt, sondern der Wert Ihrer Immobilie fach- und sachgerecht ermittelt."
            />
            <Service
              icon={cameraRetro}
              title="Fotografie und Exposés"
              description="Aussagekräftige Werbemittel sind das A und O. Deshalb werden für Sie ausschließlich hochprofessionelle Werbemittel in bester Qualität erstellt."
            />
            <Service
              icon={magic}
              title="Vermarktung"
              description="Ihre Immobilie wird proaktiv vermarktet. Mit einer regionalen und überregionalen Platzierung des Objektes können wir reichweitenstark potenzielle Käufer ansprechen."
            />
            <Service
              icon={folderOpen}
              title="Bürokratie überwinden"
              description="Die Bürokratie hat die Immobilienwirtschaft fest im Griff. Aber keine Sorge: Es werden alle notwendigen Unterlagen für Sie bereitgestellt und aufgearbeitet."
            />
            <Service
              icon={users}
              title="Besichtigung und Käuferprüfung"
              description="Von der Anfrage bis zur Besichtigung und Bonitätsprüfung. Es wird der perfekte Käufer auf Grundlage Ihrer Kriterien gefunden."
            />
            <Service
              icon={gavel}
              title="Verträge und Notar"
              description="Sobald der passende Käufer gefunden wurde, werden die letzten Schritte bis zur notariellen Beglaubigung sauber vorbereitet."
            />
          </Flex>
        </Box>
      </Flex>

      <SectionTeam />

      <Flex justifyContent="center">
        <Box width={[1, null, "50em"]} p={3}>
          <h2>Was wir noch für Sie tun können:</h2>
          <ServiceCard red collapsed>
            <h3>Bewertungen von Immobilien in Rudolstadt</h3>
            <p>
              Nutzen Sie gerne unser kostenloses Immobilienbewertungstool, um den Wert Ihrer Immobilie in 
              Rudolstadt zu ermitteln. Zusätzlich stehen wir Ihnen bei Bedarf auch bei der Erstellung eines 
              Gutachtens durch einen erfahrenen Sachverständigen zur Seite. Wir unterstützen Sie dabei, fundierte 
              Informationen über den Wert Ihrer Immobilie zu erhalten und bieten Ihnen eine umfassende Beratung für 
              einen erfolgreichen Verkaufsprozess.
            </p>
            <div>
              <LinkButton to="/immobilienbewertung-rudolstadt/">
                Jetzt Immobilie bewerten lassen
              </LinkButton>
            </div>
          </ServiceCard>
        </Box>
      </Flex>

      <Flex justifyContent="center">
        <Box width={[1, null, "50em"]} p={3}>
          <ServiceCard red collapsed>
            <h3>Direkter Ankauf von Immobilien in Rudolstadt</h3>
            <p>
              Wir sind ständig auf der Suche nach neuen Investitionsmöglichkeiten. Wenn Sie ein passendes Objekt haben, 
              sind wir gerne bereit, Ihnen ein faires und marktgerechtes Angebot zu unterbreiten. Dadurch haben Sie die Möglichkeit, 
              den Verkaufsprozess erheblich zu verkürzen und schneller zum gewünschten Ergebnis zu gelangen. 
            </p>
          </ServiceCard>
        </Box>
      </Flex>

      <Flex justifyContent="center">
        <Box width={[1, null, "70em"]} my={5} p={3} justifyContent="center">
          <SectionTitle center>Was unsere Kunden über uns sagen</SectionTitle>
          <Testimonials data={data.testimonials} />
          <Flex
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <p>Viele weitere Stimmen zu meiner Arbeit finden Sie auf Google:</p>
            <Box width={[1, "20em"]}>
              <GoogleReviews />
            </Box>
          </Flex>
        </Box>
      </Flex>
    </PageBlock>

    <PageBlock>
      <Flex justifyContent="center">
        <Box width={[1, null, "60em"]} my={5} p={3} justifyContent="center">
          <SectionTitle center>Referenzobjekte</SectionTitle>
          <Box width={[1, null, "40em"]} margin="auto">
            <p style={{ textAlign: "center" }}>
              Kranich Immobilie ist seit vielen Jahren erfolgrich als Immobilienmakler in Rudolstadt und Umgebung tätig. Sehen Sie einige Objekte, die wir in letzter Zeit verkauft haben:
            </p>
          </Box>
          <References data={data.references} />
        </Box>
      </Flex>

      <Flex justifyContent="center">
        <Box width={[1, null, "50em"]} my={4} p={3}>
          <Card collapsed>
            <Flex alignItems="center" flexWrap="wrap" mx={-3}>
              <Box width={[1, 1, 1 / 3]} px={[3, 3, 3]} mb={[3, 3, 0]}>
                <RoundedImage
                  fluid={data.founderImage.fluid}
                  alt="Linda Bäumer"
                />
              </Box>
              <Box width={[1, null, 2 / 3]} px={3}>
                <h2>
                  Linda Bäumer ist die Expertin für den Immobilienverkauf in Rudolstadt
                </h2>
                <p>
                  Linda Bäumer, Gründerin und Inhaberin von Kranich Immobilien, zählt zu den besten Immobilienmaklerinnen in Rudolstadt. 
                  Mit mehr als 8 Jahren Erfahrung verfügt sie über ein umfassendes Wissen im Immobilienverkauf, unabhängig von Art, Größe 
                  oder Lage der Immobilie. Linda und ihr engagiertes Team legen großen Wert darauf, ihren Kunden stets einen professionellen 
                  Service zu bieten, der von Ehrlichkeit und Integrität geprägt ist. 
                  Dank ihrer Sorgfalt und Verlässlichkeit hat sich Kranich Immobilien als eines der erfolgreichsten Immobilienbüros in Rudolstadt etabliert.
                </p>
              </Box>
            </Flex>
          </Card>
        </Box>
      </Flex>
    </PageBlock>
  </Page>
);

export const query = graphql`
  query {
    homeHeroImage: imageSharp(
      fluid: { originalName: { regex: "/home-hero/" } }
    ) {
      fluid(maxWidth: 1600, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    founderImage: imageSharp(fluid: { originalName: { regex: "/linda_2/" } }) {
      fluid(maxWidth: 500, maxHeight: 500, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    weimarImage: imageSharp(fluid: { originalName: { regex: "/weimar/" } }) {
      fluid(maxWidth: 500, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    references: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/src/references/" } }
      sort: { fields: [frontmatter___order] }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            location
            type
            order
            difficulty
            price
            time
          }
          html
        }
      }
    }
    testimonials: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/src/testimonials/" } }
      sort: { fields: [fileAbsolutePath] }
    ) {
      edges {
        node {
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 250, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            author
          }
          html
        }
      }
    }
  }
`;

export default ImmobilienmaklerGotha;
